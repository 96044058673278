import {CallbackFunctionVariadicAnyReturn} from "../dispatcher/eventemitter";
import {EventEmitter} from "../dispatcher";

export  class WebSocketOptions{
    forceConnection:boolean;
    connectionUrl:string;
    platform:string;
    webSocketConnector: any;

    constructor(c:string, f:boolean, p:string, w:any){
        this.connectionUrl = c;
        this.forceConnection = f;
        this.platform = p;
        this.webSocketConnector = w;
    }
};

const MIN_WEBSOCKET_RETRY_TIME = 5000;
const MAX_WEBSOCKET_RETRY_TIME = 300000;

let Socket: any;

export class FgxSDKClientClass {
    conn:WebSocket;
    connectionUrl:string;
    sequence:number;
    connectFailCount:number;
    eventCallback:CallbackFunctionVariadicAnyReturn;
    firstConnectCallback:CallbackFunctionVariadicAnyReturn;
    reconnectCallback:CallbackFunctionVariadicAnyReturn;
    errorCallback:CallbackFunctionVariadicAnyReturn;
    closeCallback:CallbackFunctionVariadicAnyReturn;
    connectingCallback:CallbackFunctionVariadicAnyReturn;
    stop:boolean;
    platform:string;
    token:string;
    timer: any;
    opts: WebSocketOptions;

    private static _instance:FgxSDKClientClass = new FgxSDKClientClass();

    constructor() {
        this.conn = null;
        this.connectionUrl = null;
        this.sequence = 1;
        this.connectFailCount = 0;
        this.eventCallback = null;
        this.firstConnectCallback = null;
        this.reconnectCallback = null;
        this.errorCallback = null;
        this.closeCallback = null;
        this.connectingCallback = null;
        this.stop = false;
        this.platform = '';
    }

    public static getInstance():FgxSDKClientClass {
        return FgxSDKClientClass._instance;
    }

    getBackoffDelay(attempt:number) {
        let R = Math.random() + 1;
        let T = MIN_WEBSOCKET_RETRY_TIME;
        let F = 2;
        let N = attempt;
        let M = MAX_WEBSOCKET_RETRY_TIME;

        return Math.floor(Math.min(R * T * Math.pow(F, N), M));
    };

    async initialize(token:string, opts:WebSocketOptions) {
        this.token = token;
        this.timer = null;
        const defaults = new WebSocketOptions(this.connectionUrl,true,'',WebSocket);

        const {connectionUrl, forceConnection, webSocketConnector, platform} = Object.assign({}, defaults, opts);

        if (platform) {
            this.platform = platform;
        }

        if (forceConnection) {
            this.stop = false;
        }

        if (this.conn) {
            return true;
        }

        if (connectionUrl == null) {
            console.log('websocket must have connection url'); //eslint-disable-line no-console
            return false;
        }

        if (this.connectFailCount === 0) {
            console.log('websocket connecting to ' + connectionUrl); //eslint-disable-line no-console
        }

        if (this.connectingCallback) {
            this.connectingCallback();
        }

        const regex = /^(?:https?|wss?):\/\/[^/]*/;
        const captured = (regex).exec(connectionUrl);
        let origin = captured[0];
        if (platform === 'android') {
            // this is done cause for android having the port 80 or 443 will fail the connection
            // the websocket will append them
            const split = origin.split(':');
            const port = split[2];
            if (port === '80' || port === '443') {
                origin = `${split[0]}:${split[1]}`;
            }
        }

        Socket = webSocketConnector;
        this.conn = new Socket(connectionUrl, [], {origin});
        this.connectionUrl = connectionUrl;
        this.opts = Object.assign({}, opts, {forceConnection: true});

        this.conn.onopen = () => {
            if (token && platform !== 'android') {
                // we check for the platform as a workaround until we fix on the server that further authentications
                // are ignored
                this.sendRequest('authenticationChallenge', {token});
            }

            if (this.connectFailCount > 0) {
                console.log('websocket re-established connection'); //eslint-disable-line no-console
                if (this.reconnectCallback) {
                    this.reconnectCallback();
                }
            } else if (this.firstConnectCallback) {
                this.firstConnectCallback();
                return;
            }

            this.connectFailCount = 0;
        };

        this.conn.onclose = () => {
            this.conn = null;
            this.sequence = 1;

            if (this.connectFailCount === 0) {
                console.log('websocket closed'); //eslint-disable-line no-console
            }

            this.connectFailCount++;

            if (this.closeCallback) {
                this.closeCallback(this.connectFailCount);
            }

            let retryTime = this.getBackoffDelay(this.connectFailCount);

            this.timer = setTimeout(
                () => {
                    if (this.stop) {
                        return;
                    }
                    this.initialize(this.token, this.opts);
                },
                retryTime
            );

        };

        this.conn.onerror = (evt:any) => {
            if (this.connectFailCount <= 1) {
                console.log('websocket error'); //eslint-disable-line no-console
                console.log(evt); //eslint-disable-line no-console
            }

            if (this.errorCallback) {
                this.errorCallback(evt);
            }
        };

        this.conn.onmessage = (evt:any) => {
            EventEmitter.emit(evt.action, evt.data);

            if (this.eventCallback) {
                this.eventCallback(evt);
            }
        };
    }

    setConnectingCallback(callback:CallbackFunctionVariadicAnyReturn) {
        this.connectingCallback = callback;
    }

    setEventCallback(callback:CallbackFunctionVariadicAnyReturn) {
        this.eventCallback = callback;
    }

    setFirstConnectCallback(callback:CallbackFunctionVariadicAnyReturn) {
        this.firstConnectCallback = callback;
    }

    setReconnectCallback(callback:CallbackFunctionVariadicAnyReturn) {
        this.reconnectCallback = callback;
    }

    setErrorCallback(callback:CallbackFunctionVariadicAnyReturn) {
        this.errorCallback = callback;
    }

    setCloseCallback(callback:CallbackFunctionVariadicAnyReturn) {
        this.closeCallback = callback;
    }

    close(stop: boolean = false) {
        this.stop = stop;
        this.connectFailCount = 0;
        this.sequence = 1;
        if (this.conn && this.conn.readyState === WebSocket.OPEN) {
            this.conn.onclose = () => {}; //eslint-disable-line no-empty-function
            this.conn.close();
            this.conn = null;
            console.log('websocket closed'); //eslint-disable-line no-console
        }
    }

    sendRequest(action:string, data:Object) {
        const msg = {
            action,
            seq: this.sequence++,
            Data: data
        };

        if (this.conn && this.conn.readyState === WebSocket.OPEN) {
            this.conn.send(JSON.stringify(msg));
        } else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
            this.conn = null;
            this.initialize(this.token,{forceConnection: true, platform: this.platform,
                webSocketConnector: Socket, connectionUrl: this.connectionUrl});
        }
    }
};