export class Objects {
    imei: string;
    name: string;
    speed: number;
    objectlogtime: string;
    idletime: string;
    angle: number;
    icon: string;
    mapicon: string;
    vin:string;
    plateno:string;
    altitude: number;
    latitude: number;
    longitude: number;
    address: string;
    driverno: string;
    loadstatus: string;
    drivername: string;
    simno: string;
    status: string;
    gprs: number; // 0 -> No Signal, 1 -> Idle, 2 -> Running
    battery: number; // 1 -> ON, 0 -> OFF
    ignition: number; // 1 -> ON, 0 -> OFF
    gps: number; // 1 -> ON, 0 -> OFF
    temp: number; // Only for Temperature Sensor Vehicles
    fuelSensor: number; // 1 -> ON, 0 -> OFF
    loadSensor: number; // 1 -> ON, 0 -> OFF
}

export class ObjectsResponse {
    Result: number;
    Reason: string;
    Response: Objects[];
}