export class ObjectsSummary {
    total: number;
    moving: number;
    idle: number;
    stop: number;
    nosignal: number;
}
export class ObjectsSummaryResponse {
    Result: number;
    Reason: string;
    Response: ObjectsSummary;
}