export class LoginRequest {
    username: string;
    password: string;
}

export class LoginResponse {
    Result: number;
    Reason: string;
    Token: string;
    Response: {
        version: string,
        name: string,
        logo: string,
        role: string,
        privileges: string[],
        initialLat: number,
        initialLng: number
    };
}