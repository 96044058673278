export class ReportsRequest {
    pageNo: number;
    pageLength: number;
    fromDate: string;
    toDate: string;
}

export class GeoFenceReportsRequest extends ReportsRequest {
    imei: string;
    zoneid: number;
}

export class DriveStartStopReportsRequest extends ReportsRequest {
    imei: string;
}

export class TemperatureReportsRequest extends ReportsRequest {
    imei: string;
    samplingTime: number;
}

export class FuelReportsRequest extends ReportsRequest {
    imei: string;
    samplingTime: number;
}

export class DrivingReportsRequest extends ReportsRequest {
    imei: string;
}

export class KmGraphReportsRequest extends ReportsRequest {
}

export class JourneyReportsRequest extends ReportsRequest {
    imei: string;
    samplingTime: number;
}

export class TravelReportsRequest extends ReportsRequest {
    imei: string;
}

export class TripReportsRequest extends ReportsRequest {
    imei: string;
}

export class DailyTravelReportsRequest extends ReportsRequest {
    imei: string;
}



