import { FgxSDKClientClass, WebSocketOptions } from "./client";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  AlertsRequest,
  LoginResponse,
  ObjectDataRequest,
  ObjectsRequest,
  ObjectsResponse,
  ObjectsSummaryResponse,
  ObjectTrackRequest,
  AlertsResponse,
  ObjectDataResponse,
  ObjectTrackResponse,
  ZonesResponse,
  PointsResponse,
  RoutesResponse,
  LoginRequest,
  SessionStatusResponse,
  WebrtcInfoResponse,
  UsersResponse,
  AddressRequest,
  AddressResponse,
  RegisterPushRequest,
  ReportsResponse,
  ReportsRequest,
} from "../models";
// Map
import {
  GetLiveTrackObjectDataRequest,
  GetLiveTrackObjectsRequest,
} from "../models/map/LiveTrackRequests";
import {
  GetLiveTrackObjectDataResponse,
  GetLiveTrackObjectsResponse,
} from "../models/map/LiveTrackResponses";
// Video Call
import { CallAlertsRequest } from "../models/videocall/CallRequests";
import { CallAlertsResponse } from "../models/videocall/CallResponses";
import {
  GetIncidentsRequest,
  IsIncidentOpenRequest,
} from "../models/videocall/IncidentRequests";
import {
  GetIncidentsResponse,
  IsIncidentOpenResponse,
} from "../models/videocall/IncidentResponses";
// CPanel
import { CpanelSummaryResponse } from "../models/cpanel/DashboardResponses";
import {
  GetAllUserPrivilegesRequest,
  GetAllUserRolesRequest,
  GetCompaniesRequest,
  ManageCompanyRequest,
  ManagePrivilegeRequest,
  ManageRoleRequest,
} from "../models/cpanel/MasterRequests";
import {
  GetAllUserPrivilegesResponse,
  GetAllUserRolesResponse,
  GetCompaniesResponse,
  ManageCompanyResponse,
  ManagePrivilegeResponse,
  ManageRoleResponse,
} from "../models/cpanel/MasterResponses";
import {
  GetUsersDropdownResponse,
  ManageUserResponse,
} from "../models/cpanel/UserResponses";
import { ManageUserRequest } from "../models/cpanel/UserRequests";
import {
  GetCpanelVehicleListRequest,
  ManageVehicleRequest,
} from "../models/cpanel/VehicleRequests";
import {
  GetCpanelVehicleListResponse,
  ManageVehicleResponse,
} from "../models/cpanel/VehicleResponses";
// Settings
import {
  GetGroupsRequest,
  SaveGroupRequest,
} from "../models/settings/UserGroupRequests";
import {
  GetGroupsResponse,
  SaveGroupResponse,
} from "../models/settings/UserGroupResponses";
import { saveZoneResponse } from "../models/settings/UserZoneResponses";
import {
  DeletePointRequest,
  SavePointRequest,
} from "../models/settings/UserPointRequests";
import { SavePointResponse } from "../models/settings/UserPointResponses";
import {
  DeleteRouteRequest,
  SaveRouteRequest,
} from "../models/settings/UserRouteRequests";
import { saveRouteResponse } from "../models/settings/UserRouteResponses";
import { DeleteZoneRequest } from "../models/settings/UserZoneRequests";

export class SdkClientClass {
  client: FgxSDKClientClass;
  url: string;
  token: string;
  isLoggedIn: boolean;
  axiosInstance: AxiosInstance;

  private static _instance: SdkClientClass = new SdkClientClass();

  public static getInstance(): SdkClientClass {
    return SdkClientClass._instance;
  }

  constructor() {
    this.client = FgxSDKClientClass.getInstance();
    this.token = "";
  }

  setUrl(baseUrl: string) {
    this.url = baseUrl;
    axios.defaults.withCredentials = true;
    this.axiosInstance = axios.create({
      baseURL: this.url,
      timeout: 10000,
      withCredentials: true,
    });
  }

  setToken(token: string) {
    this.token = token;
    this.axiosInstance.defaults.headers["fgx-api-session"] = this.token;
  }

  getToken(): string {
    return this.token;
  }

  private async doFetch<T>(
    url: string,
    body: any,
    method: string = "POST"
  ): Promise<T> {
    let response: AxiosResponse;
    if (method == "POST")
      response = await this.axiosInstance.post<Map<string, any>, AxiosResponse>(
        url,
        body
      );
    else
      response = await this.axiosInstance.get<Map<string, any>, AxiosResponse>(
        url
      );

    if (url == "/api/v2/login") {
      let tempToken = response.headers["fgx-api-session"];
      if (tempToken && tempToken != "undefined") {
        this.token = tempToken;
        console.log(`Token is set ${this.token}`);
      }
    }
    return response.data as T;
  }

  public async checkSessionStatus(): Promise<SessionStatusResponse> {
    try {
      if (this.token) {
        console.log(`Token is ${this.token}`);
        this.axiosInstance.defaults.headers["fgx-api-session"] = this.token;
      }
      let response = await this.doFetch<SessionStatusResponse>(
        "/api/v2/session_status",
        {}
      );
      if (response.Result != 200) {
        return Promise.reject(`Error occurred in session check`);
      }
      response.Token = this.token;
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async initWS() {
    try {
      let y = this.url.split("/");
      let opts = new WebSocketOptions(
        "wss://" + y[2] + "/api/v2/ws",
        true,
        "",
        WebSocket
      );
      await this.client.initialize(this.token, opts);
    } catch (e) {
      console.log(`Error ${e}`);
    }
  }

  public async login(
    username: string,
    password: string
  ): Promise<LoginResponse> {
    try {
      let model = new LoginRequest();
      model.username = username;
      model.password = password;

      let response = await this.doFetch<LoginResponse>("/api/v2/login", model);

      if (response.Result != 200) {
        return Promise.reject(`Error occurred in login ${response.Reason}`);
      }
      if (typeof response.Response === "object") {
        response.Token = "";
        response.Token = this.token;
        this.isLoggedIn = true;
        try {
          let y = this.url.split("/");
          let opts = new WebSocketOptions(
            "wss://" + y[2] + "/api/v2/ws",
            true,
            "",
            WebSocket
          );
          await this.client.initialize(response.Token, opts);
        } catch (e) {
          console.log(`Error ${e}`);
        }
        return response;
      } else {
        console.log("Login returned ", response.Reason);
        return Promise.reject(`Error occurred in login ${response.Reason}`);
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async logout() {
    try {
      await this.axiosInstance.post<any, any>("/api/v2/logout", {});
      this.isLoggedIn = false;
      this.client.close(true);
    } catch (error) {
      console.error(error);
    }
  }

  public async registerPushId(
    notificationId: string,
    platform: string,
    client: string
  ) {
    try {
      let model = new RegisterPushRequest();
      model.platform = platform;
      model.uniqueId = notificationId;
      model.client = client;

      await this.axiosInstance.post<any, any>("/api/v2/register_push", model);
    } catch (error) {
      console.error(error);
    }
  }

  public async getAlerts(model: AlertsRequest): Promise<AlertsResponse> {
    try {
      let response = await this.doFetch<AlertsResponse>(
        "/api/v2/alerts",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getAddress(model: AddressRequest): Promise<AddressResponse> {
    try {
      let response = await this.doFetch<AddressResponse>(
        "/api/v2/address",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getObjectsSummary(): Promise<ObjectsSummaryResponse> {
    try {
      let response = await this.doFetch<ObjectsSummaryResponse>(
        "/api/v2/objects_summary",
        {}
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getObjects(model: ObjectsRequest): Promise<ObjectsResponse> {
    try {
      let response = await this.doFetch<ObjectsResponse>(
        "/api/v2/objects",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getObject(
    model: ObjectDataRequest
  ): Promise<ObjectDataResponse> {
    try {
      let response = await this.doFetch<ObjectDataResponse>(
        "/api/v2/object",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getObjectTrack(
    model: ObjectTrackRequest
  ): Promise<ObjectTrackResponse> {
    try {
      let response = await this.doFetch<ObjectTrackResponse>(
        "/api/v2/object/track",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getReport<T extends ReportsRequest>(
    name: string,
    model: T
  ): Promise<ReportsResponse> {
    try {
      let response = await this.doFetch<ReportsResponse>(
        `/api/v2/reports/${name}`,
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async webrtcToken(): Promise<WebrtcInfoResponse> {
    try {
      let response = await this.doFetch<ReportsResponse>(
        `/api/v2/webrtc/${this.getToken()}`,
        {},
        "GET"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async users(): Promise<UsersResponse> {
    try {
      let response = await this.doFetch<ReportsResponse>(
        "/api/v2/users",
        {},
        "GET"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getLiveTrackObjects(
    model: GetLiveTrackObjectsRequest
  ): Promise<GetLiveTrackObjectsResponse> {
    try {
      let response = await this.doFetch<GetLiveTrackObjectsResponse>(
        "/api/v2/objects/api",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getLiveTrackVehicleData(
    model: GetLiveTrackObjectDataRequest
  ): Promise<GetLiveTrackObjectDataResponse> {
    try {
      let response = await this.doFetch<GetLiveTrackObjectDataResponse>(
        "/api/v2/object/api",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  /*
   * Video Call APIs
   */

  public async callAlerts(
    model: CallAlertsRequest
  ): Promise<CallAlertsResponse> {
    try {
      let response = await this.doFetch<CallAlertsResponse>(
        "/api/v2/callalerts",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getIncidents(
    model: GetIncidentsRequest
  ): Promise<GetIncidentsResponse> {
    try {
      let response = await this.doFetch<GetIncidentsResponse>(
        "/api/v2/incident/list",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async isIncidentOpen(
    model: IsIncidentOpenRequest
  ): Promise<IsIncidentOpenResponse> {
    try {
      let response = await this.doFetch<IsIncidentOpenResponse>(
        `/api/v2/incident/status/${model.id}`,
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  /*
   * CPanel APIs
   */

  public async getCpanelSummary(): Promise<CpanelSummaryResponse> {
    try {
      let response = await this.doFetch<CpanelSummaryResponse>(
        "/api/v2/cpanel/summary",
        {},
        "GET"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getCompanies(
    model: GetCompaniesRequest
  ): Promise<GetCompaniesResponse> {
    try {
      let response = await this.doFetch<GetCompaniesResponse>(
        "/api/v2/companies",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async manageCompany(
    model: ManageCompanyRequest
  ): Promise<ManageCompanyResponse> {
    try {
      let response = await this.doFetch<ManageCompanyResponse>(
        "/api/v2/company",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getAllUserRoles(
    model: GetAllUserRolesRequest
  ): Promise<GetAllUserRolesResponse> {
    try {
      let response = await this.doFetch<GetAllUserRolesResponse>(
        "/api/v2/roles",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async manageRole(
    model: ManageRoleRequest
  ): Promise<ManageRoleResponse> {
    try {
      let response = await this.doFetch<ManageRoleResponse>(
        "/api/v2/role",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getAllUserPrivileges(
    model: GetAllUserPrivilegesRequest
  ): Promise<GetAllUserPrivilegesResponse> {
    try {
      let response = await this.doFetch<GetAllUserPrivilegesResponse>(
        "/api/v2/privileges",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async managePrivilege(
    model: ManagePrivilegeRequest
  ): Promise<ManagePrivilegeResponse> {
    try {
      let response = await this.doFetch<ManagePrivilegeResponse>(
        "/api/v2/privilege",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getUsersDropdown(): Promise<GetUsersDropdownResponse> {
    try {
      let response = await this.doFetch<GetUsersDropdownResponse>(
        "/api/v2/users/downdown",
        {},
        "GET"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async manageUser(
    model: ManageUserRequest
  ): Promise<ManageUserResponse> {
    try {
      let response = await this.doFetch<ManageUserResponse>(
        "/api/v2/user",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getCpanelVehicleList(
    model: GetCpanelVehicleListRequest
  ): Promise<GetCpanelVehicleListResponse> {
    try {
      let response = await this.doFetch<GetCpanelVehicleListResponse>(
        "/api/v2/cpanel/vehicles",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async manageVehicle(
    model: ManageVehicleRequest
  ): Promise<ManageVehicleResponse> {
    try {
      let response = await this.doFetch<ManageVehicleResponse>(
        "/api/v2/cpanel/vehicle",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async deleteVehicle(model: DeleteZoneRequest): Promise<ZonesResponse> {
    try {
      let response = await this.doFetch<ZonesResponse>(
        "/api/v2/cpanel/vehicle/" + model.id,
        {},
        "DELETE"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  /*
   * Settings
   */

  public async getZones(): Promise<ZonesResponse> {
    try {
      let response = await this.doFetch<ZonesResponse>("/api/v2/zones", {});
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async saveZone(model: SaveGroupRequest): Promise<saveZoneResponse> {
    try {
      let response = await this.doFetch<saveZoneResponse>(
        "/api/v2/zone",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async deleteZone(model: DeleteZoneRequest): Promise<ZonesResponse> {
    try {
      let response = await this.doFetch<ZonesResponse>(
        "/api/v2/zone/" + model.id,
        {},
        "DELETE"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getPoints(): Promise<PointsResponse> {
    try {
      let response = await this.doFetch<PointsResponse>("/api/v2/points", {});
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async savePoint(model: SavePointRequest): Promise<SavePointResponse> {
    try {
      let response = await this.doFetch<SavePointResponse>(
        "/api/v2/point",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async deletePoint(model: DeletePointRequest): Promise<PointsResponse> {
    try {
      let response = await this.doFetch<PointsResponse>(
        "/api/v2/point/" + model.id,
        {},
        "DELETE"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getRoutes(): Promise<RoutesResponse> {
    try {
      let response = await this.doFetch<RoutesResponse>("/api/v2/routes", {});
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async saveRoute(model: SaveRouteRequest): Promise<saveRouteResponse> {
    try {
      let response = await this.doFetch<saveRouteResponse>(
        "/api/v2/route",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async deleteRoute(model: DeleteRouteRequest): Promise<RoutesResponse> {
    try {
      let response = await this.doFetch<RoutesResponse>(
        "/api/v2/route/" + model.id,
        {},
        "DELETE"
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async getGroups(model: GetGroupsRequest): Promise<GetGroupsResponse> {
    try {
      let response = await this.doFetch<GetGroupsResponse>(
        "/api/v2/groups",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }

  public async saveGroup(model: SaveGroupRequest): Promise<SaveGroupResponse> {
    try {
      let response = await this.doFetch<SaveGroupResponse>(
        "/api/v2/groups",
        model
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(`${error}`);
    }
  }
}
