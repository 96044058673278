export class Users {
    username: string;
    firstName: string;
    lastName: string;
}

export class UsersResponse {
    Result: number;
    Reason: string;
    Response: Users[];
}