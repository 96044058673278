export class ObjectData {
    objectlogtime: string;
    address: string;
    speed: number;
    distance: {
        today: number;
        yesterday: number;
        monthlyaverage: number;
        idleatlaststop: number; //In seconds
        numberofstops: number;
        odometer: number;
        yettotravel: number;
        nearestzone: string;
    };
    time: {
        today: number; //In seconds,
        yesterday: number; //In seconds,
        monthlyaverage: number; //In seconds,
        idleatlaststop: number; //In seconds,
        totalidletoday: number; //In seconds,
        lastupdate: string;
        sincelaststop: number; //In seconds
        idlesince: number; //In seconds
    }
}

export class ObjectDataResponse {
    Result: number;
    Reason: string;
    Response: ObjectData;
}