export class Vertex {
    h: number;
    v: number;
}

export class Zones {
    id: number;
    name: string;
    vertices: Vertex[];
    color: string;
    visible: boolean;
    namevisible: boolean;
}

export class ZonesResponse {
    Result: number;
    Reason: string;
    Response: Zones[];
}

export class PointsResponse {
    Result: number;
    Reason: string;
    Response: Zones[];
}

export class RoutesResponse {
    Result: number;
    Reason: string;
    Response: Zones[];
}