export class WebrtcInfo {
    Token:string;
    GatewayUrl:string;
    StunUri:string;
}

export class WebrtcInfoResponse {
    Result: number;
    Reason: string;
    Response: WebrtcInfo;
}