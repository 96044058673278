export class RouteDetails {
    time: string;
    latitude: number;
    longitude: number;
    speed: number;
}

export class RouteSummary {
    status: number;
    fromtime: string;
    totime: string;
    summary: string;
}

export class ObjectTrackResponse {
    Result: number;
    Reason: string;
    Response: {
        route: RouteDetails[];
        route_summary: RouteSummary[];
        route_length: number;
        top_speed: number;
        avg_speed: number;
        move_duration: number; //In Seconds
        stop_duration: number; //In Seconds
    }
}